import { z } from "zod";

export const RequestCartCheckoutSchema = z.object({
  requestId: z.string(),
  currency: z.string(),
  email: z.string().email(),
  wisherId: z.string(),
  name: z.string().optional(),
  message: z.string().optional(),
  agreedToTerms: z.literal(true, {
    errorMap: () => ({ message: "You must accept the Terms to continue." }),
  }),
  private: z.boolean(),
  gifterVisible: z.boolean(),
});

/*
 * cart request
 */
// share
export const shareCartRequestSchema = z.object({
  requestId: z.string(),
  gifterId: z.string().optional(),
  email: z.string().optional(),
  fromOrder: z.string().optional(),
});

// create
const itemLineItemSchema = z.object({ type: z.literal("item"), itemId: z.string() });
const subscriptionLineItemSchema = z.object({
  type: z.literal("subscription"),
  itemId: z.string(),
  interval: z.string(),
});
export const tipLineItemSchema = z.object({
  type: z.literal("tip"),
  tipAmount: z.string().nonempty("Required"),
  tipNote: z.string().optional().optional(),
});

const lineItemSchema = z
  .object({ quantity: z.number() })
  .and(z.union([itemLineItemSchema, subscriptionLineItemSchema, tipLineItemSchema]));

export const createCartRequestSchema = z.object({
  templateId: z.string().optional(),

  lineItems: z.array(lineItemSchema),
  templateName: z.string().optional(),

  note: z.string().max(250, "Note contains at most 250 characters.").optional(),
  gifterId: z.string().optional(),
  fromOrder: z.string().optional(),
});

export const DeleteRequestTemplateSchema = z.object({
  templateId: z.string().nonempty("Required"),
});
